import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  name: 'saleInventory',
  data() {
    return {
      requestType: 'POST',
    };
  },
  created() {
    this.getConfigList('page_list');
  },

  methods: {

  },
};
